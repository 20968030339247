import ApiIntegrface from "./api"

export function getCohorts(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/cohorts'
  api.get(url, null, callback, null, null, null)
}


export function getCohort(cohortId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/cohorts/' + cohortId
  api.get(url, null, callback,null, null, null, false)
}

export function addCohort(payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/cohorts'
  api.post(url, payload, callback, null, successMessage)
}

export function updateCohort(payload, cohortId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/cohorts/'+ cohortId
  api.put(url, payload, callback, null, successMessage)
}

export function deleteCohort(cohortId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/cohorts/' + cohortId
  api.delete(url, null, callback, null, successMessage)
}

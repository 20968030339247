<template>
<div class="mt-8 mx-8" >
  <v-data-table
    :headers="headers"
    :items="cohorts"
    sort-by="name"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat >
        <v-toolbar-title>COHORTS </v-toolbar-title>
        <v-divider class="mx-4" inset vertical ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" dark class="mb-2" v-bind="attrs" v-on="on"  >
              <v-icon class="pr-2">mdi-ev-plug-type2</v-icon>New COHORT
            </v-btn>
          </template>
           <v-form ref="form" v-model="valid" lazy-validation >

          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>

              <v-container>
                <v-row>
                  <v-col  >
                    <v-text-field :rules="[rules.required]" validate-on-blur v-model="selectedCohort.name" label="Name" ></v-text-field>
                  </v-col>
                  <v-col  >
                    <v-text-field :rules="[rules.required]" validate-on-blur v-model="selectedCohort.title" label="Title" ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col  >
                    <v-text-field  :rules="[rules.required]" validate-on-blur v-model="selectedCohort.diseases" label="Diseases" ></v-text-field>
                  </v-col>
                  <v-col  >
                    <v-text-field  :rules="[rules.required]" validate-on-blur v-model="selectedCohort.size" label="Size" ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col  >
                    <v-text-field validate-on-blur v-model="selectedCohort.details" label="Details" ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" >
                Cancel
              </v-btn>
              <v-btn :disabled="!valid" color="blue darken-1" text @click="save" >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
           </v-form>

        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">Delete Account </span>
            </v-card-title>
            <v-card-text class="body-1 mt-6 ">Are you sure you want to delete <b>{{selectedCohort.name}}</b>?</v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">YES</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip top color="orange">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              color="secondary"
              @click="deleteItem(item)"
              v-on="on"
            >
              <v-icon >mdi-delete-forever</v-icon>
            </v-btn>

        </template>
        <span>Delete <strong>{{item.cohortname}}</strong> </span>
      </v-tooltip>
      <v-tooltip top color="orange">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              color="secondary"
              @click="editItem(item)"
              v-on="on"
            >
              <v-icon >mdi-note-edit</v-icon>
            </v-btn>
        </template>
        <span> Edit {{item.name}}</span>
    </v-tooltip>


    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize" >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import { getCohorts, addCohort, deleteCohort, updateCohort } from '@/api/cohort.js'
  export default {
    data: () => ({
      valid: true,
      dialog: false,
      dialogDelete: false,
      isEditing: false,
      headers: [
        { text: 'NAME', value: 'name' ,class:"accent"},
        { text: 'TITLE', value: 'title' ,class:"accent"},
        { text: 'DISEASES', value: 'diseases',class:"accent" },
        { text: 'SIZE', value: 'size',class:"accent" },
        { text: 'DETAILS', value: 'details' ,class:"accent"},
        { text: 'ACTIONS', width:'140px', divider:true, value: 'actions', sortable: false ,class:"accent"},
      ],
      cohorts: [],
      editedIndex: -1,
      selectedCohort: {
        id: 0,
        name: '',
        title: '',
        diseases: '',
        size:0,
        details:''
      },
      defaultItem: {
        id: 0,
        name: '',
        title: '',
        diseases: '',
        size:0,
        details:''
      },
      rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          min: v => v.length >= 8 || 'Min 8 characters',
      }
    }),

    computed: {
      formTitle () {
        return ! this.isEditing  ? 'New Cohort' : 'Edit Cohort'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      loadCohorts(){
        const _this = this
        getCohorts(function(res){
          _this.cohorts = res
        })
      },
      initialize () {
        this.loadCohorts()
      },

      deleteItem (item) {
        this.selectedCohort = item
        this.dialogDelete = true
      },
      editItem (item) {
        this.selectedCohort = JSON.parse(JSON.stringify(item))
        // this.selectedSample.sampleStatus = this.selectedSample.sampleStatus.id
        // this.selectedSample.technology = this.selectedSample.technology.id
        // console.log(this.selectedSample)
        this.dialog = true

        this.isEditing = true
      },


      deleteItemConfirm () {
        const _this = this
        deleteCohort(this.selectedCohort.id, function(){
          _this.loadCohorts()
        }, 'Cohort ' + _this.selectedCohort.name + " removed successfully" )
        this.closeDelete()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.selectedCohort = Object.assign({}, this.defaultItem)
          this.isEditing = false
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.selectedCohort = Object.assign({}, this.defaultItem)
          this.isEditing = false
        })
      },

      save () {
        var isValidated = this.$refs.form.validate()
        if (isValidated){
          const _this = this
          if(this.isEditing){
            updateCohort(this.selectedCohort, this.selectedCohort.id,function(){
              _this.loadCohorts()
              _this.close()
            }, 'Cohort ' + _this.selectedCohort.name +  ' updated successfully')
          }else {
            addCohort(this.selectedCohort, function(){
              _this.loadCohorts()
              _this.close()
            }, 'Cohort ' + _this.selectedCohort.name +  ' added successfully'
            )
          }
        }
      },
    },
  }
</script>